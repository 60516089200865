import React from 'react';
import { Layout } from '../src/components';
import { LoginForm } from '../src/components/Login';
import './register.scss';

const Login = () => (
  <Layout containerClassName="auth-form" compareConnectAlert={false}>
    <LoginForm />
  </Layout>
);

Login.getInitialProps = async () => {};

export default Login;
